.end-leaderboard {
  border-radius: 20px;
  padding: 10px;
  background-color: rgb(71, 163, 255);
}

.end-leaderboard-item {
  list-style-type: none;
  background-color: white;
  height: 20px;
  width: 80vw;
  padding: 10px;
  border-radius: 50px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.end-leaderboard-item:nth-child(1) {
  background-color: #ffd700;
  -webkit-animation: gold ease-in-out 1s;
  -moz-animation: gold ease-in-out 1s;
  animation-iteration-count: infinite;

}

.end-leaderboard-item:nth-child(2) {
  background-color: #c0c0c0;
  -webkit-animation: silver ease-in-out 1.5s;
  -moz-animation: silver ease-in-out 1.5s;
  animation-iteration-count: infinite;

}

.end-leaderboard-item:nth-child(3) {
  background-color: #cd7f32;
  -webkit-animation: bronze ease-in-out 2s;
  -moz-animation: bronze ease-in-out 2s;
  animation-iteration-count: infinite;

}

@keyframes gold {
    0% {font-size: 16px; color: red}
    50% {font-size: 20px; color: green}
    100% {font-size: 16px; color: blue}
  }
  
  @-moz-keyframes gold {
    0% {font-size: 16px; color: red}
    50% {font-size: 20px; color: green}
    100% {font-size: 16px; color: blue}
  }
  
  @-webkit-keyframes gold {
    0% {font-size: 16px; color: red}
    50% {font-size: 20px; color: green}
    100% {font-size: 16px; color: blue}
  }

  @keyframes silver {
    0% {font-size: 16px;}
    50% {font-size: 20px;}
    100% {font-size: 16px;}
  }
  
  @-moz-keyframes silver {
    0% {font-size: 16px;}
    50% {font-size: 20px;}
    100% {font-size: 16px;}
  }
  
  @-webkit-keyframes silver {
    0% {font-size: 16px;}
    50% {font-size: 20px;}
    100% {font-size: 16px;}
  }

  @keyframes bronze {
    0% {font-size: 16px;}
    50% {font-size: 20px;}
    100% {font-size: 16px;}
  }
  
  @-moz-keyframes bronze {
    0% {font-size: 16px;}
    50% {font-size: 20px;}
    100% {font-size: 16px;}
  }
  
  @-webkit-keyframes bronze {
    0% {font-size: 16px;}
    50% {font-size: 20px;}
    100% {font-size: 16px;}
  }