.rule-line {
  line-height: 25px;
}

.rule-container {
  padding: 10px;
  background-color: #eeeeee;
  margin: 10px;
  border-radius: 25px;
  width: 90vw;
}

.page-home-container {
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: 150px calc(100vh - 150px);
  grid-template-areas:
    "top"
    "bottom";
}

.top {
  grid-area: top;
  text-align: center;
}

.home-outer-container {
  display: flex;
  justify-content: center;
}

.red {
  background: linear-gradient(to right, #da6b63 0%, #f84747 100%);
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
}

.red:hover {
  opacity: 1;
}

.blue {
  background: linear-gradient(to right, rgba(131,123,255,1) 0%, rgba(0,212,255,1) 100%);
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
}

.blue:hover {
  opacity: 1
}

.green {
  background: linear-gradient(to right, rgba(34,195,141,1) 0%, rgba(45,253,58,1) 100%);
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
}

.green:hover {
  opacity: 1
}

.yellow {
  background: linear-gradient(to right, rgba(253,173,45,1) 0%, rgba(255,230,0,1) 100%);
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
}

.yellow:hover {
  opacity: 1
}

.home-inner-container {
  width: 95vw;
  height: 95%;
  background: #ffffff;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  display: flex;
  justify-content: space-evenly;
  align-content: space-evenly;
  flex-wrap: wrap;
  padding: 0;
  overflow-y: auto;
  grid-area: bottom;
}

.top-buttons {
  width: calc((100% - 30px) / 2);
  height: calc((100% - 30px) / 10 * 7);
  border: none;
  border-radius: 25px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
  text-align: left;
  flex-direction: column;
}

.top-buttons > h2 {
  font-size: 1.5rem;
  margin: 15px;
}

.top-buttons > p {
  font-size: 1.25rem;
  margin: 15px;
}

.top-buttons:hover {
  box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.25);
}

.bottom-buttons {
  width: calc((100% - 30px) / 2);
  height: calc((100% - 30px) / 10 * 3);
  border: none;
  border-radius: 25px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.bottom-buttons > div{
  height: 100%;
  width: 100%;
  margin: 0;
  border-radius: 25px;
}

.bottom-buttons > div > h2 {
  font-size: 1.5rem;
  padding: 15px 15px 0 15px;
  margin: 0;
}

.bottom-buttons > div > p {
  font-size: 1.25rem;
  padding: 15px 15px 0 15px;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .top-buttons {
    padding: 10px 0;
    width: calc(100% - 20px);
    left: 50%;
    position: relative;
    transform: translate(-50%, 0%);
    margin: 10px 0;
  }

  .bottom-buttons {
    padding: 10px 0;
    width: calc(100% - 20px);
    left: 50%;
    position: relative;
    transform: translate(-50%, 0%);
    height: calc((100% - 30px) / 10);
    margin: 10px 0;
  }

  .home-inner-container {
    width: 100vw;
    height: 100%;
    box-shadow: none;
    border-radius: 0px;
    display: block;
  }

  .bottom-buttons > div {
    width: calc(100% - 40px);
    position: relative;
    transform: translate(-50%, 0%);
    border-radius: 25px;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    text-decoration: none;
    color: black;
    padding: 10px;
    margin: 10px 0;
    height: calc((100% - 30px) / 2);
  }
}
