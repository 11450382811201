* {
  font-family: "Ubuntu", Arial;
}

iframe {
  pointer-events: none;
}

html, body {
  margin: 0;
  padding: 0;
}

.page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  background: url(components/background.png);
  background-position: center;
  background-size: cover;
  /* background-repeat: no-repeat; */
}

.page-headers {
  margin: 0;
  padding-top: 20px;
}

.page-inputs {
  width: 200px;
  padding: 10px;
  border: none;
  border-radius: 50px;
  margin: 15px;
}

.page-buttons {
  border: none;
  border-radius: 50px;
  padding: 10px;
  background-color: rgb(41, 148, 255);
  color: white;
  font-size: 20px;
  margin: 10px 0;
  cursor: pointer;
  box-shadow: 0 7px rgb(0, 80, 160);
  position: relative;
}

.page-buttons-example {
  border: none;
  border-radius: 50px;
  padding: 7px;
  background-color: rgb(71, 163, 255);
  color: white;
  font-size: 15px;
  margin: 5px 0;
  cursor: pointer;
  box-shadow: 0 5px rgb(0, 80, 160);
  position: relative;
}

.page-buttons:hover {
  background-color: rgb(0, 128, 255);
}

.page-buttons-example:hover {
  background-color: rgb(0, 128, 255);
}

.page-buttons:active {
  top: 5px;
  box-shadow: 0 2px rgb(0, 128, 255);
}

.centered-box-container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.centered-box {
  max-width: calc(100vw - 40px);
  width: 400px;
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 20px 10px;
  overflow-y: auto;
}

.centered-box-lobby {
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  padding: 10px 20px;
}

.underline {
  text-decoration: underline;
}

.big-s {
  height: 40px;
}

.raise-to-meet-logo {
  position: relative;
  bottom: 4px;
  right: 3px;
}

.tagline {
  font-size: 20px;
}

.spardletan {
  height: 150px;
  margin-top: 30px;
}