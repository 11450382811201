  .entryRow {
    display: flex;
  }
  
  .entry {
    width: 15vw;
    max-height: 50px;
    max-width: 50px;
    height: 15vw;
    margin: 2.5px;
    font-family: Ubuntu;
    font-weight: 800;
    font-size: 1.5em;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
  }

  .game-timer {
    font-size: 30px;
    margin: 10px;
  }
  
  .letterContainer {
    display: flex;

  }
  
  .letterContainer:nth-child(3) {
    margin-bottom: 20px;
  }

  .letter {
    cursor: pointer;
    border-radius: 5px;
    max-width: 50px;
    width: calc(10vw - 2px);
    max-height: 50px;
    height: calc((9.09vw - 2px)/3*5);
    font-weight: 800;
    font-size: 20px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 1px;
  }

  .letter:active {
    background-color: #999999;
  }

  .game-leaderboard {
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 20px;
    background-color: #FFFFFF;
    height: 80vh;
    overflow-y: auto;
    padding: 20px;
    width: 250px;
  }

  .game-leaderboard-item {
    list-style-type: none;
    background-color: #EEEEEE;
    padding: 10px;
    border-radius: 50px;
    margin: 10px;
    border: 3px solid #DDDDDD;
  }

  #banner {
    position: absolute;
    width: 100vw;
    text-align: center;
    top: 50vh;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    color: purple;
    opacity: 0;
    font-size: 100px;
    pointer-events: none;
  }

  .fadeOut {
    -webkit-animation: fadeOut linear 1s;
    -moz-animation: fadeOut linear 1s;
  }

  @keyframes fadeOut {
    0% {opacity:1; top: 50vh;}
    100% {opacity:0; top: calc(50vh - 50px);}
  }
  
  @-moz-keyframes fadeOut {
    0% {opacity:1; top: 50vh;}
    100% {opacity:0; top: calc(50vh - 50px);}
  }
  
  @-webkit-keyframes fadeOut {
    0% {opacity:1; top: 50vh;}
    100% {opacity:0; top: calc(50vh - 50px);}
  }

  .hold-instructions {
    display: none;
  }

  @media only screen and (max-width: 1150px) {
    .game-leaderboard {
      top: 0;
      right: -320px;
      position: absolute;
      width: 300px;
      border-radius: 0px;
      padding: 10px;
      background-color: #EEEEEE;
      height: calc(50vh - 20px);
      margin: 0;
      overflow-x: hidden;
      overflow-y: auto;
      padding-left: 40px;
      transition: 0.5s right ease-in-out;
      z-index: 2;
    }

    .hold-instructions {
      display: block;
      position: absolute;
      right: 30px;
      top: 0;
      margin: 0;
    }

    .game-leaderboard:active {
      right: -0px;
    }
  
    .game-leaderboard-item {
      list-style-type: none;
      background-color: #EEEEEE;
      padding: 10px;
      border-radius: 50px;
      margin: 10px;
    }

    .hide-button {
      display: none;
    }
  }